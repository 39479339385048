<template>
  <div class="centres">
    <div>
      <!-- 左侧centres开始 -->
      <div class="centres-news">
        <div class="bordershadow">
          <!-- 类目标题开始 -->
          <div class="titleRow">
            <div class="title">{{$t("UNESCO_Centres")}}</div>
          </div>
          <!-- 类目标题结束 -->

          <!-- 左侧centres新闻内容上面5个开始 -->
          <div v-if="page">
          <ul class=" news" v-show="page.pageNum==1">
            <li
              class="newlist"
              v-for="(item, index) of mainCentres"
              :key="index"
              :class="{ cur: index % 3 != 0 }"
            >
              <a @click=" handleOpen(item.url)" target="_blank">
                <div>
                  <div class="imgs">
                    <img :src="item.thumb" alt="" />
                  </div>
                  <div class="secrow">
                    {{ item.name }}
                  </div>
                  <div class="thrrow">{{ item.country }}</div>
                </div>
              </a>
            </li>
          </ul>
          </div>
          <!-- 左侧centres新闻内容上面5个结束 -->

          <!-- 左侧centres新闻内容下面开始 -->
          <div>
            <ul class=" news">
              <li
                class="newlist"
                v-for="(item, index) of centreList"
                :key="index"
                :class="{ cur: index % 3 != 0 }"
              >
                <a @click=" handleOpen(item.url)" target="_blank">
                  <div>
                    <div class="imgs">
                      <img :src="item.thumb" alt="" />
                    </div>
                    <div class="secrow">
                      {{ item.name }}
                    </div>
                    <div class="thrrow">{{ item.country }}</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <!-- 左侧centres新闻内容下面结束 -->
        </div>
        <!-- 分页开始 current(当前页)-->
        <!--current-change,currentPage改变时会触发 current-page当前页数  page-size每页显示个数  page-count总页数  hide-on-single-page只有一页时是否隐藏-->
        <div class="pagination" v-if="page">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page.pageNum"
            background
            layout="prev, pager, next"
            :page-count="page.totalPage"
            v-show="page.totalPage > 1"
          >
          </el-pagination>
        </div>
        <!-- 分页结束 -->
      </div>
      <!-- 左侧centres结束 -->

      <!-- 右侧Latest News开始 -->
      <div class="latest-news bordershadow">
        <!-- 类目标题开始 -->
        <div class="titleRow">
          <div class="title">{{$t("Latest_News")}}</div>
          <div class="more">
            <router-link to="/News">
              {{$t("More")}}
            </router-link>
          </div>
        </div>
        <!-- 类目标题结束 -->
        <ul class="newsUlContainer" v-if="latestNews.length">
          <li v-for="(item, index) of latestNews" :key="index" class="f2Item">
            <a :href="`https://www.ikcest.org/articleS-${item.newsId}.htm`" target="_blank">
              <!-- <router-link :to="">  -->
              <div class="newsItemNoImg">
                <p class="p2row">
                  {{ item.title }}
                </p>
                <p class="dateTime">{{ item.date | formatDateTime }}</p>
              </div>
              <!-- </router-link> -->
            </a>
          </li>
        </ul>
      </div>
      <!-- f2右侧Latest News结束 -->
    </div>
        <el-dialog
      v-if="dialogVisible"
      title="提示"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="600px"
    >
      <ALink :aUrl="linkUrl" @close="handleClose"></ALink>
    </el-dialog>
  </div>
</template>

<script>
export default {
      components: {
    ALink: () => import("@/components/ALink"),
  },
  data() {
    return {
            dialogVisible: false,
      linkUrl: "22222",
      selectValue: "",
      centreList: [],
      latestNews: [],
      mainCentres: [], //前面显示的5条数据
      page: null,
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
        handleOpen(value) {
      this.dialogVisible = true;
      this.linkUrl = value;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    getData() {
      let url = "/data/api/c2ccentre.do?action=list&alias=list&pageSize=12";
      this.axios.get(url).then((res) => {
        let {
          centreList,
          latestNews,
          mainCentres,
        } = res.data.messages.data.centre;
        this.page = res.data.messages.data.page;
        this.latestNews = latestNews.slice(0, 5);
        this.mainCentres = mainCentres;
        centreList.forEach(item => {
          if(item.thumb==""){
            item.thumb="https://cdn-v1.ikcest.org/image/2021-05-31/rrePEcmrGb4srWcchTAaiQG4n5peF2WH.png"
          }
        });
        this.centreList = centreList;
        // console.log( this.centreList)
      });
    },

    handleCurrentChange(newPage) {
      this.page.pageNum = newPage;
      this.getNews();
    },

    getNews() {
      let url =
        "/data/api/c2ccentre.do?action=list&alias=list&pageSize=12&pageNum=" +
        this.page.pageNum;
      this.axios.get(url).then((res) => {
        
        this.page = res.data.messages.data.page;
        let centreListNext = res.data.messages.data.centre.centreList;

        centreListNext.forEach(item => {
          if(item.thumb==""){
            item.thumb="https://cdn-v1.ikcest.org/image/2021-05-31/rrePEcmrGb4srWcchTAaiQG4n5peF2WH.png"
          }
        });
        this.centreList = centreListNext;

        console.log(this.centreList);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep
.el-dialog{
  height: 430px !important;
}
.centres {
  padding-top: 110px;
  width: 100%;
  min-width: 1200px;
  background-color: #f7f8f8;
  color: #444546;
  > div {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

// 左侧centres开始
.centres-news {
  width: 725px;
  height: 100%;
  margin-right: 15px;
  margin-bottom: 30px;
  // border: 1px solid red;
}

.titleRow {
  margin-bottom: 8px;
}

.news {
  display: flex;
  flex-wrap: wrap;
}

.newlist {
  width: 221px;
  height: 200px;
  padding: 15px 10px;
  margin-bottom: 20px;
  border: 1px solid #dde0e1;
}

.cur {
  margin-left: 15px;
}

.imgs {
  width: 56px;
  height: 56px;
  margin: 0 auto;
  img {
    width: 56px;
    height: 56px;
    object-fit:cover;
  }
}

.f2Item{
  a{
    cursor: pointer;
  }
}

.secrow {
  // height: 72px;
  color: #222323;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin: 6px 0px;
}

.thrrow {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #009fe3;
}

.latest-news {
  width: 460px;
  height: 584px;
}

//可共用样式 驼峰
// 新闻内容
.newsUlContainer {
  li + li {
    border-top: 1px solid #dde0e1;
  }
}

.newsItem {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  .newsItem-img > img {
    width: 117px;
    height: 85px;
  }
}

.newsItemNoImg {
  padding: 10px 0px;
}

// 标题行
.titleRow {
  height: 30px;
  line-height: 30px;
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
  .title {
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      line-height: 30px;
      border-radius: 50%;
      border: 3px solid #009fe3;
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }
  .more {
    cursor: pointer;
    a {
      color: #009fe3;
    }
  }
}

// 日期样式
.dateTime {
  font-size: 12px;
  font-weight: 400;
  color: #98999a;
  padding: 10px 0px;
}

// 2行文字
.p2row {
  width: 100%;
  height: 51px;
  padding-right: 5px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}

//2行文字标题,行高20
.p2rowline20 {
  width: 100%;
  height: 42px;
  padding-right: 5px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}

// 分页
.pagination {
  margin-top: 20px;
  .btn-prev {
    margin-left: 0px;
  }
  .el-pagination {
    padding: 0px;
  }
  ::v-deep .el-pagination.is-background .btn-prev {
    background-color: white;
  }
  ::v-deep .el-pagination.is-background .btn-next {
    background-color: white;
  }
  // 修改页数默认背景颜色
  ::v-deep .el-pagination.is-background .el-pager li {
    background-color: white;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #409eff; //修改选中项背景
  }
}

//1行描述
.desc {
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #666768;
  line-height: 18px;
  padding-right: 5px;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 控制多行的行数
  -webkit-box-orient: vertical;
}
</style>
